'use strict';

require("./..\\..\\..\\bower_components\\jquery.cookie\\jquery.cookie.js");

var moduleName = 'catalog-type';

module.exports = function () {
	$('[js-catalog-type]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);



		var $wrap = $(this);



		changeType();



		$wrap.on('click', 'a', function (e) {
			e.preventDefault();

			$.cookie('ahead_view-mode', $(this).data('catalog-type'), { expires: 365, path: '/' });

			changeType();
		});



	});
};



function changeType() {
	var activeType = $.cookie('ahead_view-mode'),
		$body = $('body');

	if (activeType == 'list') {
		$body.addClass('catalog_type-row');
	} else {
		$body.removeClass('catalog_type-row');
	}
}