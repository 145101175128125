"use strict";

module.exports = function () {
	var $input = $('.city-input');
	$input
		.on('input change', function () {
			$input.attr('data-parsley-pattern', '.{9000}')
		})
		.on('blur', function () {
			$input.parsley().validate();
		});
};