"use strict";
var $ = window.jQuery = window.$ = require("./..\\..\\bower_components\\jquery\\dist\\jquery.js");

require('./modules/parsleyInit');

window.icheckInit = require('./modules/icheckInit');
window.cityChangeInit = require('./modules/cityChangeInit');
window.addCartInit = require('./modules/addCartInit');

//init wrappers for plugins
$(function () {
	window.icheckInit();
	window.cityChangeInit();
	require('./modules/slickSliderInit')();
	require('./modules/mfpInit')();
	require('./modules/datepickerInit')();
	require('./modules/spinnerInit')();
	window.addCartInit();
	require('./modules/scrollUpInit')();
	require('./modules/catalogTypeInit')();
});


