"use strict";

module.exports = function () {
	$('[scroll-up]').each(function () {
		var $button = $(this),
			$page = $('html, body'),
			$window = $(window),
			duration = 500,
			offset = 440;

		$button.toggle($window.scrollTop() > offset);

		$button.on('click', function (event) {
			event.preventDefault();

			$page.animate({scrollTop: 0}, duration);
		});

		$window.on('scroll', function () {
			$button.toggle($window.scrollTop() > offset);
		});
	});
};