"use strict";

var Pikaday = require("./..\\..\\..\\bower_components\\pikaday\\pikaday.js"),
	moment = require("./..\\..\\..\\bower_components\\moment\\moment.js");

require('../../../bower_components/moment/locale/ru');

moment.locale('ru');

module.exports = function () {
	$('[datepicker]').each(function () {
		var $input = $(this);

		new Pikaday({
			field : $input[0],
			format : 'L',
			yearRange : [1935, 2030],
			i18n : {
				previousMonth : 'Предыдущий месяц',
				nextMonth     : 'Следующий месяц',
				months        : ['Январь','Февраль','Март','Апрель','Май','Июнь',
				                 'Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
				weekdays      : ['воскресенье','понедельник','вторник','среда','четверг','пятница','суббота'],
				weekdaysShort : ['Вс','Пн','Вт','Ср','Чт','Пт','Сб']
			},
			onSelect : function () {
				if($.fn.parsley) {
					$input.parsley().validate();
				}
			}
		});
	});
};