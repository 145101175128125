require('../../../bower_components/jquery-ui/ui/core');
require('../../../bower_components/jquery-ui/ui/widget');
require('../../../bower_components/jquery-ui/ui/button');
require('../../../bower_components/jquery-ui/ui/spinner');

$.widget( "ui.spinner", $.ui.spinner, {
	_buttonHtml: function() {
		return "" +
			'<button class="ui-spinner-button ui-spinner-up" type="button"></button>' +
			'<button class="ui-spinner-button ui-spinner-down" type="button"></button>';
	}
});

function init() {
	$('[spinner]').each(function () {
		if(this.spinnerInit) return false;
		this.spinnerInit = true;

		var $input = $(this);
		var max = +$input.attr('max-quantity') || null;
		var min = +$input.attr('min-quantity') || 1;

		if($input.val() === '') {
			$input.val(1);
		}

		$input.spinner({
			min : min,
			max : max
		});

		var $widget = $input.spinner('widget');
		var $upButton = $widget.find('.ui-spinner-up');
		var $downButton = $widget.find('.ui-spinner-down');

		disableButtons($input.spinner('value'));

		$input.on( 'spin', function( event, ui ) {
			disableButtons(ui.value);
		});

		function disableButtons(val) {
			if(max) {
				$upButton.toggleClass('ui-spinner-button-disabled', val >= max);
			}

			$downButton.toggleClass('ui-spinner-button-disabled', val <= min);
		}
	});
}

module.exports = init;