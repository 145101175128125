"use strict";

function updateCart() {
    var href = '/ajax/updateCart.php',
        data = {
            'data' : $('#cart-small-to-ajax-update').val()
        };

    $.post(href, data, function(output) {
        $('#header-cart').html(output);
    });
}

var moduleName = 'add-cart';

module.exports = function () {
	$('[js-add-cart]').each(function () {
        if ($.data(this, moduleName + '-init')) return;
      		$.data(this, moduleName + '-init', true);

        var $wrap = $(this);

        $wrap.on('click', '.product-page-cart-add', function (event) {
            event.preventDefault();

            var $link = $(this),
                href = $link.attr('href');

            $.post(href, {}, function() {
                updateCart();

                $link.next().show();
                $link.remove();
            });
        });
	});
};